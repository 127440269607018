import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import SidebarData from "./SidebarData";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { IconContext } from "react-icons/lib";

const Nav = styled.div`
  background: #173a9b;
  width: 30px;
  height: 35px;
  position: fixed;
  border-radius: 8px 0 0px 8px;
`;

const NavIcon = styled(Link)`
  margin-right: 2rem;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #173a9b;
  width: 280px;
  height: 250px;
  margin-top: 200px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px 0 0px 8px;

  @media (min-width: 360px) and (max-width: 760px) {
    top: 70px;
  }
  @media (min-width: 761px) {
    top: 0px;
  }
  @media (min-width: 580px) {
    top: 20px;
  }
  @media (min-width: 610px) {
    top: 0px;
  }
`;

const SidebarWrap = styled.div`
  overflow-x: hidden;
  /* scrollbar-width: thin; */
  scrollbar-color: white;

  /* Scoped scrollbar styles */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #173a9b;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: white;
  }
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const hideSidebar = () => setSidebar(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        {/* <Nav onClick={showSidebar}>
          <span className="text-light ms-2 sidebar_span">S</span>
          <br />
          <span className="text-light ms-2 sidebar_span">I</span>
          <br />
          <span className="text-light ms-2 sidebar_span">D</span>
          <br />
          <span className="text-light ms-2 sidebar_span">E</span>
          <br />
          <span className="text-light ms-2 sidebar_span">B</span>
          <br />
          <span className="text-light ms-2 sidebar_span">A</span>
          <br />
          <span className="text-light ms-2 sidebar_span">R</span>
          <br />
        </Nav> */}

        <Nav onClick={showSidebar}>
          <span className="text-light ms-1 mb-2 sidebar_span">
            <BsChevronDoubleLeft />
          </span>
        </Nav>

        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to="#" className="p-1">
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            <SidebarData hideSidebar={hideSidebar} />
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
