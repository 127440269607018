import React, { useState, useEffect } from "react";
import "../Styles/Show.css";
import config from "../../config/Config";
import { Link } from "react-router-dom";

export default function Tags() {
  const [data, setData] = useState([]);

  function demo() {
    fetch(`${config.API_BLOG}tag`).then((result) => {
      result.json().then((res) => {
        setData(res);
      });
    });
  }

  useEffect(() => {
    demo();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="tags-container">
          {data
            .filter((item) => item.posts_count > 0)
            .map((item) => (
              <button key={item.tag_id} className="tag-button">
                <Link
                  to={`/blog/tag/${item.tag_id}`}
                  state={{ item }}
                  className="tag-link"
                >
                  {item.tag_name} ({item.posts_count})
                </Link>
              </button>
            ))}
        </div>
      </div>
    </div>
  );
}
