import { Link } from "react-router-dom";
import "../Styles/Header.css";
import Bar from "./Bar";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
function Header({ data }) {
  const router = useNavigate();
  const handleLogo = () => {
    router("/");
  };

  const logo = data[0]?.base_logo_b64;

  return (
    <div className="container-fluid header_sticky">
      <div className="row background_Color" style={{ minHeight: "40px" }}>
        <div className="col-sm-2" style={{ marginTop: "10px" }}>
          <span>
            <img
              src={logo}
              className="logo ms-1 ms-3"
              alt="Logo"
              onClick={handleLogo}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>

        <div
          className="col-sm-8 d-flex justify-content-center align-items-center"
          style={{ marginTop: "10px" }}
        >
          <ul>
            <Link to="/" className="head-top">
              <li className="nevHeader">
                <b style={{ transform: "translateY(200px)" }}>Home</b>
              </li>
            </Link>
            <Link to="/about" className="head-top">
              <li className="nevHeader">
                <b>About</b>
              </li>
            </Link>
            <Link to="/contact" className="head-top">
              <li className="nevHeader">
                <b>Contact</b>
              </li>
            </Link>
          </ul>
        </div>

        <div className="col-sm-2 mt-1">
          <Search />
          <Bar />
        </div>
      </div>
    </div>
  );
}

export default Header;
