import React, { useState, useEffect } from "react";
import "../Styles/About.css";
import config from "../../config/Config";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";

export default function About() {
  const [configuration, setConfiguration] = useState([]);
  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    fetchConfiguration();
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  return (
    <div className="Container-fluid">
      <Helmet>
        <title>About</title>
        <meta name="title" content={metaTitle} />
        <meta name="keywords" content={metaKeywords} />
        <meta name="description" content={metaDescription} />
      </Helmet>

      <center style={{ fontSize: "38px", fontFamily: "sofia" }}>
        <b>
          About<b style={{ color: "#173a9b" }}> Us</b>
        </b>
        <hr className="w-25 mx-auto" />
      </center>

      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <div className="row p-2">
            <div className=" col-md-12 col-lg-6">
              <div className="row" data-aos="fade-up">
                <img
                  src="https://www.xenonstack.com/hubfs/deep-learning-challenges.png"
                  data-aos="zoom-in"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6" data-aos="zoom-in">
              <b className="heading_font text_color_heading">Who We Are</b>
              <br />
              <span className="para_font_family">
                Welcome to{" "}
                <a
                  href="https://ilearnings.in/"
                  target="_blank"
                  title="ilearnings"
                >
                  ilearnings
                </a>
                your go-to destination for all things related to modern web
                technologies. We specialize in providing high-quality blogs,
                tutorials, and resources on Magento, Laravel, and database
                management. Our mission is to empower developers and tech
                enthusiasts with actionable knowledge and insights to enhance
                their skills and solve real-world challenges.
              </span>
              <br />
              <b className="heading_font text_color_heading">Our Mission</b>
              <br />
              <span className="para_font_family">
                Our mission is to build a community where technology enthusiasts
                can learn, share, and grow. We aim to simplify complex concepts
                and provide practical solutions that drive innovation and
                efficiency.
              </span>
              <br />
              <b className="heading_font text_color_heading">Our Philosophy</b>
              <br />
              <span className="para_font_family">
                Making our customers happy is our highest priority. The constant
                improvement on our client-driven roadmap rests on a desire to
                satisfy our clients and exceed expectations. We provide quality
                without any compromise.
              </span>
            </div>
          </div>
          <div className="row p-2 mt-3">
            <b className="heading_font text_color_heading">
              Technologies You Cover :-
            </b>
            <div className="col-sm-12 col-md-12">
              <b className="para_font_family">Magento:-</b>
              <span className="para_font_family">
                {" "}
                Tips for customization, PWA development, performance
                optimization, and more.
              </span>
              <br />
              <b className="para_font_family">Laravel:-</b>
              <span className="para_font_family">
                {" "}
                Tutorials on building web apps, implementing authentication, and
                advanced features.
              </span>
              <br />
              <b className="para_font_family">Database:-</b>
              <span className="para_font_family">
                {" "}
                Insights on design, optimization, queries, and integration with
                modern frameworks.
              </span>
            </div>
          </div>
          <div className="row mb-2 mt-2 p-2">
            <b className="heading_font text_color_heading">
              Lesson-Wise Topics for Blogs :-
            </b>
            <div
              className="col-sm-12 col-md-12 col-lg-4 mb-3"
              data-aos="zoom-in"
            >
              <div className="card card-5">
                <b className="heading_font p-2 text_color_heading">Magento:-</b>
                <span className="text_family_about p-2">
                  Getting Started with Magento: Installation and Setup.
                  Customizing Magento Themes: A Beginner's Guide. Understanding
                  Magento GraphQL APIs. Magento Performance Optimization
                  Techniques. Exploring Magento 2 PWA Studio. Payment
                  Integration and Troubleshooting in Magento. Managing Magento
                  Extensions and Updates. Mastering Magento for a seamless
                  e-commerce experience
                </span>
              </div>
            </div>
            <div
              className="col-sm-12 col-md-12 col-lg-4 mb-3"
              data-aos="zoom-in"
            >
              <div className="card card-5">
                <b className="heading_font p-2 text_color_heading">Laravel:-</b>
                <span className="text_family_about p-2">
                  Introduction to Laravel: Setting Up Your First Project.
                  Building a REST API in Laravel: A Step-by-Step Guide. Laravel
                  Authentication: Login, Registration, and Dashboard Access.
                  Using Eloquent ORM for Database Operations. Laravel
                  Middleware: What It Is and How to Use It. Advanced Topics:
                  Task Scheduling and Queues in Laravel. Integrating Third-Party
                  APIs in Laravel Projects.
                </span>
              </div>
            </div>
            <div
              className="col-sm-12 col-md-12 col-lg-4 mb-3"
              data-aos="zoom-in"
            >
              <div className="card card-5">
                <b className="heading_font p-2 text_color_heading">
                  Databases:-
                </b>
                <span className="text_family_about p-2">
                  Introduction to Relational Databases: Basics and Concepts.
                  Designing a Database Schema: Best Practices. Writing Efficient
                  SQL Queries for Beginners. Exploring Database Relationships:
                  One-to-Many, Many-to-Many. Optimizing Database Performance:
                  Indexing and Query Optimization. Integrating Databases with
                  Laravel Applications. Migrating and Versioning Databases with
                  Laravel Migrations.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
