import React, { useState, useEffect } from "react";
import "../Styles/Show.css";
import { useParams } from "react-router-dom";
import config from "../../config/Config";
import { Link } from "react-router-dom";
export default function Lessondata() {
  const [data, setData] = useState([]);
  const [showTitles, setShowTitles] = useState(false);
  const { id } = useParams();
  function demo() {
    fetch(`${config.API_TUTORIAL}tutorials?key=${id}`).then((result) => {
      result.json().then((res) => {
        setData(res);
      });
    });
  }

  useEffect(() => {
    demo();
  }, []);

  const toggleTitles = () => {
    setShowTitles(!showTitles);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <span
          style={{ color: "white", fontSize: "20px", cursor: "pointer" }}
          onClick={toggleTitles}
        >
          <b className="ms-1 heading_font" style={{ fontSize: "18px" }}>
            Lessons
          </b>
        </span>
        <div>
          <ul
            className="lesson-list"
            style={{
              listStyleType: "disc",
              paddingLeft: "20px",
              color: "white",
            }}
          >
            {data.map((item) =>
              item?.lesson_title?.split(",").map((title, index) => (
                <li key={`${item.lesson_id}-${index}`}>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "14px",
                    }}
                    to={`/less/${item.lesson_id}`}
                    state={{ item }}
                    className="lesson-link para_font_family"
                  >
                    {title}
                  </Link>
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
