import React, { useState, useEffect, useRef } from "react";
import "../Styles/Contect.css"; // Fixed file name typo in CSS
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config/Config";
import { Helmet } from "react-helmet";

export default function Contact() {
  const [configuration, setConfiguration] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    comment: "",
  });
  const [recaptcha, setRecaptcha] = useState("");
  const captchaRef = useRef(null);
  const [errors, setErrors] = useState({});

  // Fetch site configuration
  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        const response = await fetch(`${config.API_BLOG}configuration`);
        const data = await response.json();
        setConfiguration(data);
      } catch (err) {
        console.error("Error fetching configuration:", err);
      }
    };
    fetchConfiguration();
  }, []);

  // Validate form fields
  const validateForm = (data) => {
    const validationErrors = {};

    if (!data.name) validationErrors.name = "Name is required.";
    if (!data.email) {
      validationErrors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      validationErrors.email = "Please enter a valid email address.";
    }
    if (!data.number) {
      validationErrors.number = "Phone number is required.";
    } else if (!isValidNumber(data.number)) {
      validationErrors.number = "Please enter a valid 10-digit phone number.";
    }
    if (!data.comment) validationErrors.comment = "Comment is required.";
    if (!recaptcha)
      validationErrors.recaptcha = "Please complete the reCAPTCHA.";

    return validationErrors;
  };

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidNumber = (number) => /^[0-9]{10}$/.test(number);

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch(`${config.API_BLOG}contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        toast.success(result.message || "Comment submitted successfully!");
        setFormData({ name: "", email: "", number: "", comment: "" });
        captchaRef.current.reset();
        setRecaptcha("");
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  // Handle reCAPTCHA change
  const onRecaptchaChange = (value) => {
    setRecaptcha(value);
  };

  const SITE_KEY = "6LeaRaYqAAAAAFJM4Kd4lNGuqo3O9e9y-JeeWtwT";

  const metaTitle = configuration[0]?.default_meta_title || "Contact Us";
  const metaKeywords =
    configuration[0]?.default_meta_keywords || "contact, feedback";
  const metaDescription =
    configuration[0]?.default_meta_description || "Get in touch with us.";

  return (
    <div className="container-fluid mt-5 mb-5">
      {/* Helmet for SEO */}
      <Helmet>
        <title>contact</title>
        <meta name="title" content={metaTitle} />
        <meta name="keywords" content={metaKeywords} />
        <meta name="description" content={metaDescription} />
      </Helmet>

      {/* Contact Form */}
      <div className="row con">
        <div className="col-sm-3"></div>
        <div className="col-sm-6 col-md-12 col-lg-6 box">
          <p className="box1">CONTACT US</p>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                type="text"
                placeholder="Name"
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </div>
            <br />
            <div>
              <input
                type="email"
                placeholder="Email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
            <br />
            <div>
              <input
                type="text"
                placeholder="Phone Number"
                className={`form-control ${errors.number ? "is-invalid" : ""}`}
                name="number"
                value={formData.number}
                onChange={handleChange}
              />
              {errors.number && (
                <div className="invalid-feedback">{errors.number}</div>
              )}
            </div>
            <br />
            <div>
              <textarea
                placeholder="Comment"
                className={`form-control ${errors.comment ? "is-invalid" : ""}`}
                name="comment"
                value={formData.comment}
                onChange={handleChange}
              />
              {errors.comment && (
                <div className="invalid-feedback">{errors.comment}</div>
              )}
            </div>
            <br />
            <div>
              <ReCAPTCHA
                sitekey={SITE_KEY}
                onChange={onRecaptchaChange}
                ref={captchaRef}
              />
              {errors.recaptcha && (
                <div className="invalid-feedback">{errors.recaptcha}</div>
              )}
            </div>
            <br />
            <center>
              <button
                type="submit"
                style={{
                  height: "40px",
                  width: "200px",
                  borderRadius: "10px",
                  background: "#173a9b",
                  color: "white",
                  marginBottom: "20px",
                }}
              >
                Submit
              </button>
            </center>
          </form>
        </div>
        <div className="col-sm-3"></div>
      </div>
      <ToastContainer />
    </div>
  );
}




