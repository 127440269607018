import React, { useState, useEffect } from "react";
import "../Styles/Show.css";
import config from "../../config/Config";
import { Link } from "react-router-dom";

export default function SideBarTags({ hideSidebar }) {
  const [data, setData] = useState([]);
  const [showTitles, setShowTitles] = useState(false);

  function demo() {
    fetch(`${config.API_BLOG}tag`).then((result) => {
      result.json().then((res) => {
        setData(res);
      });
    });
  }

  useEffect(() => {
    demo();
  }, []);

  const toggleTitles = () => {
    setShowTitles(!showTitles);
  };

  return (
    <div className="container-fluid">
      <div className="row" style={{ transform: "translateY(-15px)" }}>
        <span
          style={{ color: "white", fontSize: "20px", cursor: "pointer" }}
          onClick={toggleTitles}
        >
          <span className="ms-3 heading_font" style={{ fontSize: "18px" }}>
            Tags
          </span>
        </span>
        {showTitles && (
          <div>
            <ul
              className="lesson-list"
              style={{
                listStyleType: "disc",
                paddingLeft: "20px",
                color: "white",
              }}
            >
              {data
                .filter((item) => item.posts_count > 0)
                .map((item) => (
                  <li key={item.id} className="card-body fs-6 ms-3">
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontSize: "14px",
                      }}
                      to={`/blog/tag/${item.tag_id}`}
                      state={{ item }}
                      onClick={hideSidebar}
                      className="para_font_family"
                    >
                      {item.tag_name} ({item.posts_count})
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
