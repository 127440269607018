import React, { useState, useEffect } from "react";
import "../Styles/Tutorial.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import TutorialSidebar from "./TutorialSidebar";
import config from "../../config/Config";
import { Helmet } from "react-helmet";
import Loader from "./Loader";
import NotFound from "./NotFound";
export default function TutorialSeries() {
  const [configuration, setConfiguration] = useState([]);
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  function fetchSeries() {
    let url;
    if (id) {
      url = `${config.API_TUTORIAL}getseries?key=${id}`;
    } else {
      url = `${config.API_TUTORIAL}getseries`;
    }
    fetch(url)
      .then((result) => result.json())
      .then((res) => setDatas(res));
    setIsLoading(false);
  }

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    setIsLoading(true);
    fetchSeries();
    fetchConfiguration();
  }, [id]);

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  const renderHTML = (rawHTML) => {
    return { __html: rawHTML };
  };

  const filteredData = datas.filter((item) => item.status === 1);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <div className="text-center mt-5">
          <Loader />
        </div>
      ) : filteredData.length === 0 ? (
        <div className="text-center">
          <NotFound />
        </div>
      ) : (
        <>
          {/* ***************************************** */}

          <Helmet>
            <title>TutorialSeries</title>
            <meta name="title" content={metaTitle} />
            <meta name="keywords" content={metaKeywords} />
            <meta name="description" content={metaDescription} />
          </Helmet>

          {/* ************************************************ */}
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
              <center style={{ fontSize: "38px", fontFamily: "sofia" }}>
                <b>
                  Tutorial<b style={{ color: "#173a9b" }}> Series</b>
                </b>
                <hr className="w-25 mx-auto" />
              </center>
              <div className="row">
                {filteredData.map((item) => {
                  const limitedContentTutorials = (() => {
                    const parser = new DOMParser();
                    const plainText =
                      parser.parseFromString(
                        item.tutorial_series_description,
                        "text/html"
                      ).body.textContent || "";
                    const letterOnlyText = plainText.replace(/[^a-zA-Z ]/g, "");
                    let letterCount = 0;
                    let limitedText = "";
                    for (let i = 0; i < letterOnlyText.length; i++) {
                      if (/[a-zA-Z]/.test(letterOnlyText[i])) {
                        letterCount++;
                      }
                      if (letterCount <= 200) {
                        limitedText += letterOnlyText[i];
                      } else {
                        break;
                      }
                    }
                    return limitedText + (letterCount > 200 ? "..." : "");
                  })();

                  return (
                    <div
                      className="col-md-12 col-lg-12 col-xl-6  mb-3 card_box"
                      key={item.id}
                    >
                      <div className="card mt-2 p-3 ">
                        <Link
                          to={`/tutorial/${item.tutorial_series_name}`}
                          state={{ item }}
                          className="tutorial"
                        >
                          <b className="heading_font">
                            {item.tutorial_series_name}
                          </b>
                        </Link>
                        <div className="row">
                          <div className="col-sm-3">
                            <center className="p-2">
                              <img
                                src={item.tutorial_series_image_b64}
                                style={{ width: "100px" }}
                                alt={item.tutorial_series_image_b64}
                              />
                            </center>
                          </div>
                          <div
                            className="col-sm-9 mt-2 para_font_family"
                            style={{ textAlign: "justify", minHeight: "140px" }}
                          >
                            <span
                              dangerouslySetInnerHTML={renderHTML(
                                limitedContentTutorials
                              )}
                            ></span>

                            <div className="row">
                              <Link
                                to={`/tutorial/${item.tutorial_series_name}`}
                                state={{ item }}
                                style={{
                                  height: "20px",
                                  lineHeight: "8px",
                                  fontSize: "15px",
                                  textDecoration: "none",
                                }}
                              >
                                <b
                                  style={{ float: "right", marginTop: "10px" }}
                                >
                                  &gt; Show More
                                </b>
                              </Link>
                            </div>
                          </div>
                          <div className="col-sm-12 mt-2">
                            <center>
                              <div
                                className="card-header"
                                style={{
                                  minHeight: "35px",
                                  lineHeight: "20px",
                                  background: "#173a9b",
                                }}
                              >
                                <div className="row text-light para_font_family">
                                  <div className="col-sm-6">
                                    <p
                                      className="card-text"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <b>#Lessons:</b> {item?.lessons_count}
                                    </p>
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="card-text text-light"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <b>Technology:</b> {item.technologies}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-sm-1"></div>
          </div>
          <div className="side1">
            <TutorialSidebar />
          </div>
        </>
      )}
    </div>
  );
}
