// import React from "react";
// import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
// import "./index.css";
// import App from "./App";
// import Bar from "./Components/Pages/Bar";
// import { AiOutlineSearch } from "react-icons/ai";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { UserProvider } from "./context/UserContext";

// // Create a functional component
// function MyComponent() {
//   return (
//     <div className="" style={{ overflow: "hidden" }}>
//       <div className="col-sm-12">
//         <App />
//       </div>
//     </div>
//   );
// }

// ReactDOM.render(
//   <BrowserRouter>
//     {" "}
//     <UserProvider>
//       <MyComponent />
//     </UserProvider>
//   </BrowserRouter>,
//   document.getElementById("root")
// );

import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import ScrollToTop from "react-scroll-to-top";


import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="" style={{ overflow: "hidden" }}>
    <div className="col-sm-12">
      <App />
      <ScrollToTop smooth style={{ marginRight: "-30px" }} />
    </div>
  </div>
);


