import React, { useState, useEffect } from "react";
import "../Styles/Show.css";
import { Link } from "react-router-dom";
import config from "../../config/Config";
export default function TechnologySiderbar({ closeSidebar }) {
  const [data, setData] = useState([]);
  const [showTitles, setShowTitles] = useState(false);
  function demo() {
    fetch(`${config.API_TUTORIAL}technology`).then((result) => {
      result.json().then((res) => {
        setData(res);
      });
    });
  }

  useEffect(() => {
    demo();
  }, []);

  const toggleTitles = () => {
    setShowTitles(!showTitles);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <span
          style={{
            color: "white",
            fontSize: "18px",
            cursor: "pointer",
          }}
          onClick={toggleTitles}
          className="heading_font"
        >
          Technology
        </span>
        {
          <ul
            className="lesson-list"
            style={{
              listStyleType: "disc",
              paddingLeft: "30px",
              color: "white",
            }}
          >
            {data
              .filter((item) => item.technology_count > 0)
              .map((item) => (
                <li key={item.id}>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "14px",
                    }}
                    to={`/tutorialseries/technology/${item.technology_id}`}
                    state={{ item }}
                    className="para_font_family"
                    onClick={closeSidebar}
                  >
                    {item?.technology_name} ({item?.technology_count})
                  </Link>
                </li>
              ))}
          </ul>
        }
      </div>
    </div>
  );
}
