import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import config from "../../config/Config";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import parse from "html-react-parser";
import { decode } from "html-entities";
import Loader from "./Loader";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import ShareButton from "./ShareButton";
export default function Tutorial() {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [configuration, setConfiguration] = useState([]);
  const { id } = useParams();

  // console.log(id, "%%%%%%%%%%%%%%%%%%%%%%%%%%");

  function fetchData() {
    fetch(`${config.API_TUTORIAL}tutorial`)
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  function renderDescription(description) {
    const preTagRegex = /<pre[^>]*>(?:<code[^>]*>)?(.*?)(?:<\/code>)?<\/pre>/gs;
    let parts = [];
    let lastIndex = 0;
    let match;

    while ((match = preTagRegex.exec(description)) !== null) {
      if (match.index > lastIndex) {
        const nonCodePart = description.slice(lastIndex, match.index);
        const updatedNonCodePart = parse(
          nonCodePart.replace(
            /<a /g,
            '<a target="_blank" rel="noopener noreferrer" '
          )
        );
        parts.push(updatedNonCodePart);
      }

      // Decode and process the code block
      let decodedCode = decode(match[1].trim());
      decodedCode = decodedCode.replace(/<br\s*\/?>/g, "");
      decodedCode = decodedCode.replace(/<\/?strong>/g, "");

      const codeBlock = (
        <div
          className="code-container"
          key={match.index}
          style={{ position: "relative" }}
        >
          <SyntaxHighlighter language="javascript" style={oneDark}>
            {decodedCode}
          </SyntaxHighlighter>
          <div className="code-buttons p-1">
            <button onClick={() => copyCode(decodedCode)}>Copy</button>
            <button onClick={(event) => openRow(decodedCode, event)}>
              Simple Text
            </button>
            <button onClick={() => openExternal(decodedCode)}>External</button>
          </div>
        </div>
      );

      parts.push(codeBlock);
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < description.length) {
      // Process any remaining part of the description
      const remainingPart = description.slice(lastIndex);
      const updatedRemainingPart = parse(
        remainingPart.replace(
          /<a /g,
          '<a target="_blank" rel="noopener noreferrer" '
        )
      );
      parts.push(updatedRemainingPart);
    }

    return parts;
  }

  function copyCode(code) {
    navigator.clipboard.writeText(code).then(() => {
      toast.success("Code copied to clipboard!");
    });
  }

  function openExternal(code) {
    const win = window.open("", "_blank", "width=800,height=600,resizable=yes");
    const escapedCode = code
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    win.document.write(
      `<pre style="white-space: pre-wrap;">${escapedCode}</pre>`
    );
    win.document.close();
  }

  function openRow(code, event) {
    const button = event.target;
    const container = button.closest(".code-container");
    const preTag = container.querySelector("pre");

    if (!preTag) return;

    if (preTag.dataset.original) {
      // Restore the original code block with syntax highlighting
      preTag.innerHTML = preTag.dataset.original;
      preTag.removeAttribute("data-original");
      button.textContent = "Simple Text";
    } else {
      // Replace with plain text
      preTag.dataset.original = preTag.innerHTML;
      preTag.textContent = code; // Display plain text only
      button.textContent = "Simple Text";
    }
  }

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    fetchData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsLoading(true);
    fetchConfiguration();
  }, []);

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  const seriesData = data.filter((item) => item.tutorial_series_name == id);

  let tutorialSeriesName = seriesData.map((item) => item.tutorial_series_name);
  let tutorialSeriesDescription = seriesData.map((item) => (
    // dangerouslySetInnerHTML={renderHTML(item.tutorial_series_description)}
    <div>
      {renderDescription(
        item?.tutorial_series_description || "<p>No description available</p>"
      )}
    </div>
  ));
  // let tutorialSeriesImg = seriesData?.map((item) => (
  //   <img
  //     src={item.tutorial_series_image_b64}
  //     style={{ height: "128px", width: "128px" }}
  //     alt={item.tutorial_series_name}
  //   />
  // ));

  const tutorialSeriesImg =
    seriesData.length > 0 ? seriesData[0].tutorial_series_image_b64 : "";

  const goBack = () => {
    window.history.back();
  };

  const baseUrl = `https://ilearnings.in/tutorial/${encodeURIComponent(id)}`;

  const imageUrl = `https://ilearnings.in/logo1.png`;
  
  return (
    <div className="container-fluid">
      {isLoading ? (
        <div className="text-center mt-5">
          <Loader />
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-1"></div>
          <div className="col-sm-10 mb-4">
            <div class="card mt-5 p-2">
              {/* <div
                className="card-header text-center"
                style={{ background: "#173a9b" }}
              >
                <h3 className="card-title text-light heading_font mb-0">
                  {tutorialSeriesName[0]}
                </h3>
              </div> */}
              <div
                className="card-header"
                style={{
                  backgroundColor: "#173a9b",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ color: "#fff" }}>
                  <button className="btn btn-secondary" onClick={goBack}>
                    <IoMdArrowRoundBack />
                  </button>
                </span>
                <span
                  className="card-title text-light mb-0 heading_font"
                  style={{ textAlign: "center", flex: 1 }}
                >
                  {tutorialSeriesName[0]}
                </span>
                <span style={{ color: "#fff" }}>
                  <ShareButton Tutorial_data={seriesData} />
                </span>
              </div>

              <div className="row">
                <div className="col-sm-3">
                  <h5>
                    {" "}
                    <center>
                      <div className="p-3">
                        <img
                          src={tutorialSeriesImg}
                          alt="tutorialSeriesImg"
                          onClick={() => setIsOpen(true)}
                          style={{ cursor: "pointer", maxWidth: "100px" }}
                        />
                        {isOpen && (
                          <Lightbox
                            mainSrc={tutorialSeriesImg}
                            onCloseRequest={() => setIsOpen(false)}
                            imageCaption="A beautiful forest"
                          />
                        )}
                      </div>
                    </center>
                  </h5>
                </div>
                <div className="col-sm-9">
                  <h5 className="card-title mt-3 para_font_family">
                    {tutorialSeriesDescription[0]}
                  </h5>
                </div>
              </div>

              <b className="heading_font fs-3">Lessons</b>
              <div className="col-sm-6 col-md-12 col-lg-6">
                <ul
                  className="lesson-list"
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                    color: "#173a9b",
                  }}
                >
                  {seriesData.map((item) => (
                    <>
                      {/* Helmet Section */}
                      <Helmet>
                        <title>{tutorialSeriesName[0]}</title>
                        <meta
                          name="title"
                          content={`${item.series_meta_title || metaTitle}`}
                        />
                        <meta
                          name="keywords"
                          content={`${
                            item.series_meta_keywords || metaKeywords
                          }`}
                        />
                        <meta
                          name="description"
                          content={`${
                            item.series_meta_description || metaDescription
                          }`}
                        />

                        {/* <-----------------------------------share----------------------------------------------> */}

                        <title>{tutorialSeriesName[0]}</title>
                        <meta name="keywords" content={tutorialSeriesName[0]} />
                        <meta
                          name="description"
                          content={tutorialSeriesName[0]}
                        />

                        {/* <!-- Twitter OG --> */}
                        <meta
                          name="twitter:card"
                          content="summary_large_image"
                        />
                        <meta
                          name="twitter:title"
                          content={tutorialSeriesName[0]}
                        />
                        <meta
                          name="twitter:description"
                          content={tutorialSeriesName[0]}
                        />
                        <meta name="twitter:url" content={baseUrl} />
                        <meta name="twitter:image" content={imageUrl} />

                        {/* <!-- Facebook OG --> */}
                        <meta property="og:locale" content="en_US" />
                        <meta
                          property="og:title"
                          content={tutorialSeriesName[0]}
                        />
                        <meta
                          property="og:description"
                          content={tutorialSeriesName[0]}
                        />
                        <meta property="og:url" content={baseUrl} />
                        <meta
                          property="og:site_name"
                          content="https://ilearnings.in"
                        />
                        <meta property="og:type" content="website" />
                        <meta property="og:image" content={imageUrl} />

                        {/* <!-- LinkedIn OG --> */}
                        <meta
                          property="og:title"
                          content={tutorialSeriesName[0]}
                        />
                        <meta
                          property="og:description"
                          content={tutorialSeriesName[0]}
                        />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={baseUrl} />
                        <meta property="og:image" content={imageUrl} />

                        {/* <-----------------------------------share----------------------------------------------> */}
                      </Helmet>
                      {/* Lesson Titles */}

                      {item?.lesson_title?.split(",").map((title, index) => (
                        <li key={`${item.lesson_id}-${index}`}>
                          <Link
                            style={{ textDecoration: "none", color: "#173a9b" }}
                            to={`/less/${item.lesson_id}`}
                            state={{ item }}
                            className="lesson-link para_font_family"
                          >
                            {title}
                          </Link>
                        </li>
                      ))}
                    </>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-1"></div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}
