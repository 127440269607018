import React from "react";
import Archives from "./Archives";
import SideBarTags from "./SideBarTags";
import Blogcategory from "./Blogcategory";

export default function SidebarData({ hideSidebar }) {
  return (
    <div className="row">
      <Blogcategory hideSidebar={hideSidebar} />
      <Archives />
      <SideBarTags />
    </div>
  );
}
