import React from "react";
import TechnologySiderbar from "./TechnologySiderbar";

export default function TutorialSideData({ closeSidebar }) {
  return (
    <div>
      <TechnologySiderbar closeSidebar={closeSidebar} />
    </div>
  );
}
